<template>
  <v-container
    class="grey lighten-5 mb-6"
    style="height: 100vh;"
  >
    <v-row
      no-gutters
      justify="center"
      style="align-items: center;height: 100vh;"
    >
      <v-col cols="12">
        <v-card
          class="pa-2"
          align="center"
          outlined
          tile
        >
          {{ msg }}
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { apiCommonauthUsers } from '@/api'

export default {
  name: 'Verify',

  data: vm => ({
    msg: vm.$t('page.verify.verifying')
  }),

  async mounted() {
    const response = await apiCommonauthUsers.head(this.$route.params.code)
    if (response.status === 200) {
      this.msg = this.$t('page.verify.verified')
      setTimeout(() => this.$router.push({
        name: 'Login',
        params: {
          ...this.$route.params,
          lang: this.$i18n.locale
        },
        query: this.$route.query
      }), 3000)
    } else {
      this.msg = this.$t('page.verified_wrong')
    }
  }
}
</script>

<style scoped lang="scss"></style>
